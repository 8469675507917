<template>
  <div>
    <MdAutocomplete
      v-model="input"
      :md-options="selectOptionset()"
      @md-selected="updateSelected"
      @md-changed="updateOptions"
      md-dense
      :md-fuzzy-search="false"
      :md-input-placeholder="placeHolder"
      id="start"
    >
      <template slot="md-autocomplete-item" slot-scope="{ item, term }">
        <Md-highlight-text :md-term="term.location">{{
          item.location
        }}</Md-highlight-text>
        <Md-badge
          class="md-square"
          v-for="tag in item.tags"
          :class="tag.color"
          :key="tag.name"
          :md-content="tag.displayName"
        />
      </template>
    </MdAutocomplete>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { queryPointOfInterests, queryEnds } from "@/api/addressAPI";
import _ from "lodash";

const ow = {
  location: "Canal Exit",
  lat: 0,
  lng: 0,
};

export default {
  name: "InputEnd",
  mixins: [validationMixin],
  props: {
    enableOpenwater: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeHolder: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      input: "",
      selected: {},
      options: undefined,
      defaultOptions: [],
    };
  },
  watch: {
    enableOpenwater: function (newVal) {
      if (!newVal && this.input.includes("Canal Exit")) {
        this.input = "";
        this.selected = {};
      }
    },
  },
  created() {
    this.updateOptions = _.debounce((searchTerm) => {
      this.options = new Promise((resolve) => {
        if (searchTerm && searchTerm !== this.selected.location) {
          queryEnds(searchTerm, !this.enableOpenwater).then((response) => {
            resolve(response.data);
          });
        } else {
          this.options = undefined;
          resolve(this.defaultOptions);
        }
      });
    }, 500);
  },
  async mounted() {
    this.defaultOptions = await queryPointOfInterests(
      "",
      !this.enableOpenwater
    ).then((response) => {
      return response.data;
    });
  },
  methods: {
    updateSelected(e) {
      this.input = e.location;
      this.selected = e;
      this.$emit("select-change", e);
    },
    selectOptionset() {
      if (!this.options || this.input === "") {
        return this.enableOpenwater
          ? [ow, ...this.defaultOptions]
          : this.defaultOptions;
      } else return this.options;
    },
  },
  computed: {},
};
</script>

<style lang="scss">
@import "@/styles/colors";
.md-menu-content.md-theme-default {
  max-width: 450px;
}

@media (min-width: 500px) {
  .md-me1nu-content.md-theme-default {
    max-width: 450px;
  }
}
</style>
