<template>
  <div id="app" ref="app" class="fill-height">
    <Popup />

    <div class="params">
      <div class="md-layout flex-layout">
        <div class="
            md-layout-item
            md-size-95
            md-medium-size-80
            md-large-size-85
            md-xsmall-size-70
            logo
          ">
          <a href="https://iboatmap.com" target="_blank">
            <picture>
              <!--       <source
              media="(max-width: 350px)"
              srcset="/img/Logo_IBoatMap_Sign.png"
            />
            <source
              media="(max-width: 799px)"
              srcset="/img/Logo_IBoatMap_L_vertical.png"
            />
            <source
              media="(min-width: 800px)"
              srcset="/img/Logo_IBoatMap_L.png"
            /> -->
              <img class="img-logo" src="/img/iBOATMAP_Logo_dark_L.png" alt="IBoatMap Logo" />
            </picture>
          </a>
        </div>
        <div class="
            md-layout-item
            md-size-5
            md-medium-size-20
            md-large-size-10
            md-xsmall-size-30
            socials
          ">
          <a href="https://www.instagram.com/iboatmap/" target="_blank" class="pull-right">
            <Instagram size="32" />
          </a>
          <a href="https://www.facebook.com/iboatmap" target="_blank" class="pull-right">
            <Facebook size="32" />
          </a>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <Banner type="header" class="md-layout-item md-size-100 md-xsmall-size-100 banner" />
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-40 md-xsmall-size-100">
          <InputEnd ref="start" @select-change="onSelectChange" placeHolder="Enter start address or poi" />
        </div>
        <div class="md-layout-item md-size-40 md-xsmall-size-100">
          <InputEnd ref="end" :enableOpenwater="enableOpenWater" placeHolder="Enter destination address or poi" />
        </div>
        <div class="md-layout-item md-size-10 params">
          <md-button class="md-raised md-primary show-button" @click="locationChanged">calculate</md-button>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <Alert v-if="showAlert">{{ alertMessage }}</Alert>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-40 md-xsmall-size-60">
          <a href="https://iboatmap.com/add-missing-addresses/" target="_blank">Address or poi not available?</a>
        </div>
        <!-- <div class="md-layout-item md-size-40 md-xsmall-size-40">
          <a href="https://iboatmap.com/add-missing-addresses/" target="_blank"
            >How to use?</a
          >
        </div> -->
      </div>
    </div>
    <div id="results" class="grow fill-height">
      <google-map id="map" ref="googlemap" :activePOILayers="selectedTags" :showAlert.sync="showAlert"
        :alertMessage.sync="alertMessage" />
    </div>
    <div>
      <Legend :selectedTags="selectedTags" />
    </div>
    <div class="md-layout md-gutter">
      <Banner type="footer" class="md-layout-item md-size-100 md-xsmall-size-100 banner" />
    </div>
    <div class="md-layout md-gutter">
      <p class="md-layout-item md-size-100 md-xsmall-size-100 disclaimer">
        Disclaimer: This tool is for ESTIMATING the distance and travel time and
        is for general information purposes only. This tool should not be the
        basis for a real estate purchase decision. This tool should not be
        relied upon for navigation. Depending on your start point there may be
        locks, no-wake zones, bridges, depth variations, speed limits, and other
        boating restrictions and variables that could affect this approximate
        calculation. We explicitly point this out and ask you, regardless of the
        results of the calculator, to use only the official and approved routes,
        to adhere to the speed limits and to comply with all other regulations
        and laws of the State of Florida and The United States of America. Your
        use of the site and your reliance on any information on the site occurs
        solely at your own risk and under no circumstance shall we have any
        liability to you for any loss or damage of any kind because of your use
        and reliance. If you make this tool available to your customers in any
        form, you are obligated to actively promote the disclaimer and indemnify
        Vacation in Florida Inc or TrustID GmbH against claims of any kind. The
        integration of the iBoatmap application on websites without a valid
        license agreement from Vacation in Florida Inc. is strictly forbidden.
        Purchasing a license is possible at the following link:
        <a href="https://business.iboatmap.com/#!/pricing" target="_blank"
          class="md-layout-item md-size-100 md-xsmall-size-100 disclaimer">https://business.iboatmap.com</a>
        ©️2019-2023 Vacation in Florida Inc.
      </p>
    </div>
  </div>
</template>

<script>
import GoogleMap from "./components/GoogleMap.vue";
import InputEnd from "./components/InputEnd.vue";
import Banner from "./components/Banner.vue";
import Facebook from "vue-material-design-icons/Facebook.vue";
import Instagram from "vue-material-design-icons/Instagram.vue";
import Legend from "./components/Legend.vue";
import Popup from "./components/Popop.vue";
import Alert from "./components/Alert.vue";

export default {
  name: "app",
  components: {
    Alert,
    GoogleMap,
    InputEnd,
    Banner,
    Facebook,
    Instagram,
    Legend,
    Popup,
  },
  data() {
    return {
      enableOpenWater: true,
      selectedTags: [],
      showAlert: false,
      alertMessage: "",
    };
  },
  methods: {
    locationChanged() {
      var start = this.$refs.start;
      var end = this.$refs.end;

      if (start.selected.location === end.selected.location) {
        this.alertMessage =
          "Really? You tried to trick me, but I was paying attention! 1:0 for me! Please select another destination address :)";
        this.showAlert = true;
        return;
      }

      this.$emit("calc");

      this.$refs.googlemap.update(
        { lat: start.selected.lat, lng: start.selected.lng },
        { lat: end.selected.lat, lng: end.selected.lng }
      );

      document.getElementById("map").scrollIntoView();
    },
    onSelectChange(e) {
      if (e.location.includes("CAPE CORAL")) {
        this.enableOpenWater = true;
      } else {
        this.enableOpenWater = false;
      }
    },
  },
};
</script>

<style lang="scss">
html,
body {
  height: 99%;
}

.params {
  padding: 0px 10px 0px 10px;
}

.md-button.show-button {
  margin-top: 16px;
}

.md-menu-content.md-theme-default {
  max-width: unset !important;
}

.md-menu-content.md-select-menu {
  width: unset !important;
}

@media (max-width: 600px) {
  .md-button.show-button {
    margin: 0px;
  }

  .params {
    padding-bottom: 20px;
  }

  .disclaimer {
    font-size: 8px;
    line-height: 10px;
  }
}

.fill-height {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.fill-height>.grow {
  flex: 1;
  flex-grow: 1;
}

.banner {
  display: flex;
  justify-content: center;
  flex-grow: unset !important;
}

.img-logo {
  max-height: 150px;
}

.logo {
  display: flex;
}

.flex-layout {
  display: flex;
  align-items: fle;
  flex-direction: row;
  justify-content: flex-end;
}

.socials {
  padding-right: 10px;
  align-self: center;

  a {
    color: inherit;
    text-decoration: none;
  }
}

.pull-right {
  margin-left: auto;
  float: right;
}

.disclaimer {
  color: #565656;
  font-size: 10px;
  line-height: 12px;
}
</style>
