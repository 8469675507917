<!-- see https://medium.com/founders-factory/building-a-custom-google-map-component-with-vue-js-d1c01ddd0b0a -->

<template>
  <div class="grow fill-height wrapper">
    <div id="result-row">
      <div class="md-layout md-gutter">
        <div
          class="md-layout-item md-size-20 md-medium-size-40 md-xsmall-size-60"
        >
          The distance is
        </div>
        <div
          class="md-layout-item md-size-20 md-medium-size-40 md-xsmall-size-40"
        >
          ~
          <span class="strong"
            >{{ result.lengthMiles }} miles ({{ result.lengthKm }} km)</span
          >.
        </div>
        <div
          class="md-layout-item md-size-20 md-medium-size-40 md-xsmall-size-60"
        >
          The travel time is
        </div>
        <div
          class="md-layout-item md-size-20 md-medium-size-40 md-xsmall-size-40"
        >
          ~
          <span class="strong">{{ result.travelTime }} min</span>.
        </div>
      </div>
    </div>

    <div id="google-map" class="grow"></div>

    <div v-if="showWaiting">
      <div id="overlay">
        <PulseLoader />
      </div>
    </div>
  </div>
</template>

<script>
import MapService from "./MapService.js";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import {
  loadTags,
  queryPointOfInterestsByTag,
  loadIcon,
} from "@/api/addressAPI";

export default {
  name: "google-map",
  components: {
    PulseLoader,
  },
  props: {
    activePOILayers: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: function () {
    return {
      poiMarkers: {},
      layers: undefined,
      showWaiting: true,
      showResult: false,
      startIcon: undefined,
      endIcon: undefined,
      result: {
        lengthMiles: 0,
        lengthKm: 0,
        travelTime: 0,
      },
    };
  },
  watch: {
    activePOILayers: function (selectedTags) {
      if (window.google) {
        selectedTags.forEach((tag) => {
          if (this.poiMarkers[tag]) {
            this.layers.set(tag, this.$map);
          } else {
            queryPointOfInterestsByTag(tag).then((result) => {
              const pois = result.data;
              for (const poi of pois) {
                const marker = new window.google.maps.Marker({
                  position: new window.google.maps.LatLng(poi.lat, poi.lng),
                  animation: window.google.maps.Animation.DROP,
                  label: poi.displayName,
                  icon: poi.icon
                    ? {
                        url: `/img/map-icons/${poi.icon.name}`,
                        scaledSize: {
                          width: poi.icon.width,
                          height: poi.icon.height,
                        },
                      }
                    : undefined,
                });
                marker.bindTo("map", this.layers, tag);
              }
              this.poiMarkers[tag] = true;
              this.layers.set(tag, this.$map);
            });
          }
        });

        //remove all unselected markers
        Object.keys(this.layers).forEach((layer) => {
          if (!selectedTags.includes(layer) && !layer.startsWith("gm_")) {
            this.layers.set(layer, null);
          }
        });
      }
    },
  },
  mounted: function () {
    this.loadIcons();
    this.$mapService = new MapService();
    this.$mapService.init().then((google) => {
      this.layers = new google.maps.MVCObject();

      let layerConfig = {};
      loadTags().then((tags) => {
        tags.data.forEach((tag) => (layerConfig[tag.name] = null));
        this.layers.setValues(layerConfig);
      });

      this.$map = new google.maps.Map(document.getElementById("google-map"), {
        restriction: {
          latLngBounds: {
            north: 27.747773,
            south: 25.616292,
            west: -82.835565,
            east: -81.17187,
          },
          strictBounds: false,
        },
      });

      var noPoi = [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        },
      ];
      this.$map.setOptions({ styles: noPoi });

      this.$center = {
        zoom: 12,
        center: new google.maps.LatLng(26.58273, -81.991257),
      };

      this.$polylines = [];
      this.$markers = [];

      this.clear();
      this.showWaiting = false;
      google.maps.event.trigger(this.$map, "resize");
    });
  },
  methods: {
    update: function (start, end) {
      if (start == undefined && end == undefined) {
        this.clear();
      } else {
        this.showWaiting = true;
        this.showResult = false;
        this.$emit("update:showAlert", false);
        this.$emit("update:alertMessage", "");
        this.clear();

        this.$mapService
          .getPath(start.lat, start.lng, end?.lat, end?.lng)
          .then((res) => {
            this.result = res.data;
            // If another call meanwhile added a new poly
            this.clear();

            if (typeof res.data === "string") {
              throw res;
            }
            const startPoint = res.data.path[0];
            const endPoint = res.data.path[res.data.path.length - 1];

            const google = window.google;

            res.data.lineConfig.forEach((config) => {
              const poly = new google.maps.Polyline({
                path: res.data.path.slice(
                  config.startIndex,
                  config.endIndex + 1
                ),
                strokeColor: config.color,
                strokeOpacity: 1.0,
                strokeWeight: 3,
              });

              this.$polylines.push(poly);
              poly.setMap(this.$map);
            });

            const startMarker = new google.maps.Marker({
              position: startPoint,
              map: this.$map,
              animation: google.maps.Animation.DROP,
              icon: this.startIcon,
            });

            this.$markers.push(startMarker);

            const endMarker = new google.maps.Marker({
              position: endPoint,
              map: this.$map,
              animation: google.maps.Animation.DROP,
              icon: this.endIcon,
            });

            this.$markers.push(endMarker);
            var bounds = new google.maps.LatLngBounds();
            bounds.extend(new google.maps.LatLng(startPoint));
            bounds.extend(new google.maps.LatLng(endPoint));
            this.$map.fitBounds(bounds);
            this.$map.panToBounds(bounds);
            google.maps.event.trigger(this.$map, "resize");
            this.showResult = true;
            this.showWaiting = false;
          })
          .catch((err) => {
            this.showWaiting = false;
            this.$emit("update:showAlert", true);
            this.$emit("update:alertMessage", err.data);
          });
      }
    },
    clear: function () {
      for (let i = 0; i < this.$polylines.length; i++) {
        this.$polylines[i].setMap(null);
      }

      for (let i = 0; i < this.$markers.length; i++) {
        this.$markers[i].setMap(null);
      }

      this.$polylines = [];
      this.$markers = [];

      if (this.$map) this.$map.setOptions(this.$center);
    },
    loadIcons() {
      loadIcon("Start").then(
        (response) =>
          (this.startIcon = {
            url: `/img/map-icons/${response.data.name}`,
            scaledSize: {
              width: response.data.width,
              height: response.data.height,
            },
          })
      );

      loadIcon("End").then((response) => {
        this.endIcon = {
          url: `/img/map-icons/${response.data.name}`,
          scaledSize: {
            width: response.data.width,
            height: response.data.height,
          },
        };
      });
    },
  },
};
</script>

<style scoped>
#result-row {
  padding: 0px 10px 20px 10px;
  font-size: 16px;
}

#result-row .md-layout-item {
  padding-top: 10px;
}

.strong {
  font-weight: bold;
}

.v-spinner {
  margin: 20px;
  text-align: center;
  vertical-align: middle;
}

#overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.wrapper {
  position: relative;
  min-height: 300px;
}
</style>
