import request from "./request";

export function getCID() {
  return new URLSearchParams(window.location.search).get("cid");
}

export function fetchPopUp() {
  return request({
    method: "GET",
    url: "/p?cid=" + getCID(),
  });
}

export function saveMail(email, firstName, lastName) {
  return request({
    method: "POST",
    url: "/p",
    data: { email, firstName, lastName, cid: getCID() },
  });
}
