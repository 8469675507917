import Vue from "vue";
import App from "./App.vue";
import {
  MdAutocomplete,
  MdMenu,
  MdField,
  MdList,
  MdButton,
  MdProgress,
  MdSnackbar,
  MdBadge,
  MdHighlightText,
  MdDivider,
  MdCheckbox,
  MdDialog,
} from "vue-material/dist/components";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import store from "./store";

Vue.config.productionTip = false;
Vue.use(MdAutocomplete);
Vue.use(MdMenu);
Vue.use(MdField);
Vue.use(MdList);
Vue.use(MdButton);
Vue.use(MdProgress);
Vue.use(MdSnackbar);
Vue.use(MdBadge);
Vue.use(MdHighlightText);
Vue.use(MdDivider);
Vue.use(MdCheckbox);
Vue.use(MdDialog);

new Vue({
  el: "#app",
  store,
  render: (h) => h(App),
});
