<template>
  <md-dialog
    :md-active.sync="showDialog"
    :md-click-outside-to-close="config.closable"
    :md-close-on-esc="config.closable"
    :md-fullscreen="false"
    :class="{ frame: inIframe() }"
  >
    <md-dialog-title>{{ config.title }}</md-dialog-title>
    <md-dialog-content class="">
      <p>{{ config.description }}</p>
      <form novalidate class="md-layout" @submit.prevent="validateEmail">
        <div class="md-layout md-gutter md-alignment-center-space-between">
          <div
            class="md-layout-item md-small-size-100"
            v-if="config.showFirstName"
          >
            <md-field :class="getValidationClass('firstName')">
              <label for="first-name">First Name</label>
              <md-input
                name="first-name"
                id="first-name"
                autocomplete="given-name"
                v-model="form.firstName"
                :disabled="sending"
              />
              <span class="md-error" v-if="!$v.form.firstName.required"
                >The first name is required</span
              >
              <span class="md-error" v-else-if="!$v.form.firstName.minlength"
                >Invalid first name</span
              >
            </md-field>
          </div>

          <div
            class="md-layout-item md-small-size-100"
            v-if="config.showLastName"
          >
            <md-field :class="getValidationClass('lastName')">
              <label for="last-name">Last Name</label>
              <md-input
                name="last-name"
                id="last-name"
                autocomplete="family-name"
                v-model="form.lastName"
                :disabled="sending"
              />
              <span class="md-error" v-if="!$v.form.lastName.required"
                >The last name is required</span
              >
              <span class="md-error" v-else-if="!$v.form.lastName.minlength"
                >Invalid last name</span
              >
            </md-field>
          </div>
        </div>

        <md-field :class="getValidationClass('email')">
          <label for="email">Email</label>
          <md-input
            type="email"
            name="email"
            id="email"
            autocomplete="email"
            v-model="form.email"
            :disabled="sending"
            class=""
          />
          <span class="md-error" v-if="!$v.form.email.required"
            >The email is required</span
          >
          <span class="md-error" v-else-if="!$v.form.email.email"
            >Invalid email</span
          >

          <span class="md-error" v-else-if="invalidMailError">{{
            invalidMailError
          }}</span>
        </md-field>
        <button type="submit" ref="submit" style="display: none" />
      </form>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button
        v-if="config.closable"
        class="md-primary"
        @click="showDialog = false"
        >Close</md-button
      >
      <md-button
        type="submit"
        class="md-primary float-right"
        :disabled="sending"
        @click="$refs.submit.click()"
        >Save</md-button
      >
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { fetchPopUp, saveMail, getCID } from "@/api/popup";
import { validationMixin } from "vuelidate";
import {
  required,
  requiredIf,
  email,
  minLength,
} from "vuelidate/lib/validators";
export default {
  name: "Popup",
  mixins: [validationMixin],
  computed: {
    firstNameRequired() {
      return this.config.firstNameRequired;
    },
    lastNameRequired() {
      return this.config.lastNameRequired;
    },
  },
  data() {
    return {
      showDialog: false,
      config: {},
      calc: 0,
      invalidMailError: undefined,
      form: {
        email: "",
        firstName: null,
        lastName: null,
      },
      sending: false,
    };
  },
  validations: {
    form: {
      firstName: {
        required: requiredIf(function () {
          return this.firstNameRequired;
        }),
        minLength: minLength(3),
      },
      lastName: {
        required: requiredIf(function () {
          return this.lastNameRequired;
        }),
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
    },
  },
  mounted() {
    fetchPopUp().then((res) => {
      this.config = res.data;
      switch (this.config.openStrategy) {
        case "COUNTED":
          this.counted(this.config.calcCount);
          break;
        case "TIMED":
          this.timed(this.config.seconds);
          break;
      }
    });
  },

  methods: {
    validateEmail() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.save();
      }
    },
    save() {
      saveMail(this.form.email, this.form.firstName, this.form.lastName)
        .then((res) => {
          if (res.status === 200 && res.data) {
            this.showDialog = false;
            let cids = JSON.parse(window.localStorage.getItem("pd"));
            if (!cids) cids = [];
            cids.push(getCID());
            window.localStorage.setItem("pd", JSON.stringify(cids));
          }
        })
        .catch((err) => {
          this.invalidMailError = err.response.data;
          this.$v.$touch();
        });
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid":
            (field.$invalid && field.$dirty) || this.invalidMailError,
        };
      }
    },
    timed(secs) {
      setTimeout(() => this.open(), secs * 1000);
    },
    counted(amount) {
      this.calc = window.localStorage.getItem("calc");
      if (this.calc >= amount) {
        this.open();
      }

      this.$parent.$on("calc", () => {
        this.calc++;
        window.localStorage.setItem("calc", this.calc);
        if (this.calc >= amount) {
          this.open();
        }
      });
    },
    open() {
      const cids = JSON.parse(window.localStorage.getItem("pd"));
      if (!cids || !cids.includes(getCID())) {
        this.showDialog = true;
      }
    },
    inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
  },
};
</script>

<style lang="scss">
.float-right {
  float: right;
}
.p-20 {
  padding: 20px !important;
}

.frame {
  .md-dialog-container {
    margin-top: 30px !important;
  }
}
</style>
