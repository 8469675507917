<template>
  <div class="legend">
    <div v-for="tag in availableTags" :key="tag.name">
      <md-checkbox
        v-if="tag.selectable"
        v-model="selectedTags"
        :value="tag.name"
        class="md-primary"
        ><span><img :src="`/img/map-icons/${tag.icon.name}`" /></span>
        {{ tag.displayName }}</md-checkbox
      >
      <span v-else
        ><img :src="`/img/map-icons/${tag.icon.name}`" />
        {{ tag.displayName }}
      </span>
    </div>
  </div>
</template>

<script>
import { loadTags } from "@/api/addressAPI";

export default {
  name: "Legend",
  components: {},
  props: {
    selectedTags: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      availableTags: [],
    };
  },
  mounted() {
    this.loadAvailableTags();
  },
  methods: {
    loadAvailableTags() {
      loadTags().then((response) => {
        const tags = response.data.sort((first) => (first.selectable ? -1 : 1));
        this.availableTags = tags;
      });
    },
  },
};
</script>

<style lang="scss">
.legend {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  div > span {
    margin: 16px 16px 16px 0;
  }

  span > img {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;

    .md-checkbox {
      margin: 8px 8px 8px 0 !important;
    }

    div > span > img {
      margin: 8px 8px 8px 0;
    }
  }
}

.md-checkbox {
  align-items: center;
}

.md-checkbox-label {
  height: unset !important;
}
</style>
