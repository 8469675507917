import request from "./request";

export function queryStartAddress(search) {
  return request({
    method: "GET",
    url: "/search?type=address&t=START&q=" + search,
  });
}

export function queryDestinations(search) {
  return request({
    method: "GET",
    url: "/search/?type=poi&t=END&q=" + search,
  });
}

export function queryEnds(search) {
  return request({
    method: "GET",
    url: "/search/?type=address%7Cpoi&t=END&q=" + search,
  });
}

export function queryPointOfInterests(search) {
  return request({
    method: "GET",
    url: "/search?type=poi&t=poi&q=" + search,
  });
}

export function queryPointOfInterestsByTag(search) {
  return request({
    method: "GET",
    url: "/poi?t=" + search,
  });
}

export function loadTags() {
  return request({
    method: "GET",
    url: "/tags/all",
  });
}

export function loadIcon(name) {
  return request({
    method: "GET",
    url: "/icon/" + name,
  });
}
