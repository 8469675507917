<template>
  <div
    role="alert"
    class="v-alert v-sheet elevation-0 v-alert--dense error"
    file="v-alert/usage"
  >
    <div class="v-alert__wrapper">
      <span aria-hidden="true" class="v-icon notranslate v-alert__icon"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          role="img"
          aria-hidden="true"
          class="v-icon__svg"
        >
          <path
            d="M8.27,3L3,8.27V15.73L8.27,21H15.73C17.5,19.24 21,15.73 21,15.73V8.27L15.73,3M9.1,5H14.9L19,9.1V14.9L14.9,19H9.1L5,14.9V9.1M11,15H13V17H11V15M11,7H13V13H11V7"
          ></path></svg
      ></span>
      <div class="v-alert__content"><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.error {
  background-color: #ff5252;
  border-color: #ff5252;
}
.v-alert:not(.v-sheet--tile) {
  border-radius: 4px;
}

.v-alert {
  display: block;
  font-size: 16px;
  margin-bottom: 16px;
  padding: 16px;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-alert--dense {
  padding-top: 8px;
  padding-bottom: 8px;
}
.v-alert__wrapper {
  align-items: center;
  border-radius: inherit;
  display: flex;
}
.v-icon {
  align-items: center;
  display: inline-flex;
  font-feature-settings: "liga";
  font-size: 24px;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-indent: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.v-alert__icon {
  align-self: flex-start;
  border-radius: 50%;
  height: 24px;
  min-width: 24px;
  position: relative;
  margin-right: 16px;
  color: #fff;
}
.v-alert__content {
  flex: 1 1 auto;
  color: #fff;
}
.v-icon {
  color: #fff;
}
.v-icon__svg {
  fill: currentColor;
}
</style>
